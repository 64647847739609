import React, { useEffect, useState } from 'react'
import './home.scss'
import { ApiUrl } from '../../env'
import { useSelector } from 'react-redux'
import Menu from '../../elements/menu'
import api from '../../api'
import moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import dayjs from 'dayjs';
import throttling from 'lodash/debounce';

const corner_list = [
  'pinsa',
  'ikigai',
  'crepe brulee',
  'corner for friends',
  'darte',
  'bagel shop',
  'beer',
  'natasha',
  'urban',
]


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


const Home = () => {
  const token = useSelector((state) => state.userSelector.token)
  const [data, setData] = useState([])
  const [pageInfo, setPageInfo] = useState([])   
  const [loading, setLoading] = useState(true)
  const [exportDisabled, setExportDisabled] = useState(false)

  const [search, setSearch] = useState('')
  const [corner, setCorner] = useState('')
  const [order_datetime_from, setOrder_datetime_from] = useState(null)
  const [order_datetime_to, setOrder_datetime_to] = useState(null)
  const [price_up, setPrice_up] = useState(null)
  const [price_to, setPrice_to] = useState(null)
  const [payment_method, setPayment_method] = useState(null)
  const [initialized, setInitialized] = useState(false);


  const exportFunc = () => {
    setExportDisabled(true)
    let order_datetime_from_formated = moment(order_datetime_from, "YYYY-MM-DDTHH:mm:ss").format()
    let order_datetime_to_formated = moment(order_datetime_to, "YYYY-MM-DDTHH:mm:ss").format()
    if (order_datetime_from_formated === 'Invalid date') {
      order_datetime_from_formated = null
    }
    if (order_datetime_to_formated === 'Invalid date') {
      order_datetime_to_formated = null
    }
    let filter = {
      search: search,
      corner: corner,
      order_time_from: order_datetime_from_formated,
      order_time_to: order_datetime_to_formated,
      price_up: price_up,
      price_to: price_to,
      payment_method: payment_method,
    }
    api.export(token, filter).then(response => {
      console.log(response.data)
      window.open(`${ApiUrl}/${response.data.message}`, '_blank');
    })
    setExportDisabled(false)
  }
  const getDataFunc = () => {

    let order_datetime_from_formated = moment(order_datetime_from, "YYYY-MM-DDTHH:mm:ss").format()
    let order_datetime_to_formated = moment(order_datetime_to, "YYYY-MM-DDTHH:mm:ss").format()
    if (order_datetime_from_formated === 'Invalid date') {
      order_datetime_from_formated = null
    }
    if (order_datetime_to_formated === 'Invalid date') {
      order_datetime_to_formated = null
    }
    let filter = {
      search: search,
      corner: corner,
      order_time_from: order_datetime_from_formated,
      order_time_to: order_datetime_to_formated,
      price_up: price_up,
      price_to: price_to,
      payment_method: payment_method,
    }
    setLoading(true)
    api.getData(token, filter).then(
      response => {
        setData(response.data.results)
        setPageInfo(
          {
            count: response.data.count,
            current_page: response.data.current_page,
            total_pages: response.data.total_pages,
            next: response.data.next,
            previous: response.data.previous,

          }
        )        
      }
    ).catch(error => {
      console.log(error)
    })
    setLoading(false)
  }
  const getByUrl = (url) => {
    url = url.replace(/^https?:\/\/[^/]+/, '')
    setLoading(true)
    api.getUrl(token, url).then(
      response => {
        setData(response.data.results)
        setPageInfo(
          {
            count: response.data.count,
            current_page: response.data.current_page,
            total_pages: response.data.total_pages,
            next: response.data.next,
            previous: response.data.previous,

          }
        )        
      }
    ).catch(error => {
      console.log(error)
    })
    setLoading(false)
  }

  useEffect(() => {
    var currentDate = new Date();
    var firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    var lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    setOrder_datetime_from(firstDayOfMonth)
    setOrder_datetime_to(lastDayOfMonth)
    setInitialized(true)
  }, [token])

  useEffect(() => {
    if (initialized) {
      getDataFunc()
    }
  }, [
    corner,
    order_datetime_from,
    order_datetime_to,
    price_up,
    price_to,
    payment_method,
    initialized])

  const debouncedGetDataFunc = throttling(getDataFunc, 300)

  const handleSearchChange = (e) => {
    setSearch(e.target.value)
    debouncedGetDataFunc()
  };


  return (
    <>
      <Menu />
      <div className='mainFilter'>
        <Button disabled={exportDisabled} onClick={exportFunc} className='downloadButton' variant="contained">Export CSV</Button>
        <div className='filterDiv'>
          <FormControl full>
            <TextField value={search} onPaste={handleSearchChange} onChange={handleSearchChange} label="Search" variant="outlined" />
          </FormControl>

          <FormControl full>
            <InputLabel id="demo-simple-select-label">Corner</InputLabel>
            <Select
              value={corner}
              label="Corner"
              onChange={(e) => setCorner(e.target.value)}
            >
              <MenuItem className='filterInput' value=''>All</MenuItem>
              {corner_list.map(corner =>
                <MenuItem key={corner} className='filterInput' value={corner}>{corner}</MenuItem>
              )}
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel>Pay method</FormLabel>
            <RadioGroup
              row
              onChange={(e) => setPayment_method(e.target.value)}
            >
              <FormControlLabel value="" control={<Radio />} label="All" />
              <FormControlLabel value="card" control={<Radio />} label="Card" />
              <FormControlLabel value="cache" control={<Radio />} label="Cache" />
            </RadioGroup>
          </FormControl>


        </div>
        <div className='filterDiv'>
          <div className='rangeFilterDiv'>
            <TextField value={price_up} onChange={e => setPrice_up(e.target.value)} className='rangeFilter' label="Price up" variant="outlined" type="number" />
            <TextField value={price_to} onChange={e => setPrice_to(e.target.value)} className='rangeFilter' label="Price to" variant="outlined" type="number" />
          </div>


          <div className='rangeFilterDiv'>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker ampm={false} value={dayjs(order_datetime_from)} id='setOrder_datetime_from' onChange={e => setOrder_datetime_from(e.$d)} label="Datetime from" />
              </DemoContainer>
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DateTimePicker']}>
                <DateTimePicker ampm={false} value={dayjs(order_datetime_to)} id='setOrder_datetime_to' onChange={e => setOrder_datetime_to(e.$d)} label="Datetime to" />
              </DemoContainer>
            </LocalizationProvider>
          </div>



        </div>
      </div>
      <div className='mainTable'>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" >Order number (<b>{data.length}/{pageInfo.count}</b>)</StyledTableCell>
                <StyledTableCell >Corner</StyledTableCell>
                <StyledTableCell >Datetime</StyledTableCell>
                <StyledTableCell >Content</StyledTableCell>
                <StyledTableCell >Price</StyledTableCell>
                <StyledTableCell align="center">Pay method</StyledTableCell>
              </TableRow>
            </TableHead>
            {loading ?
              <div className='loadingDataDiv'>
                <img src="https://i.gifer.com/ZKZg.gif" alt="" />
              </div>
              :
              <TableBody>
                {data.length === 0 ?
                  <div className='loadingDataDiv'>
                    <h3>Empty</h3>
                  </div>
                  :
                  data.map((row) => (
                    <StyledTableRow key={row.id} className='dataRow'>
                      <StyledTableCell align="center" ><b>{row.order_number}</b></StyledTableCell>
                      <StyledTableCell ><b>{row.corner}</b></StyledTableCell>
                      <StyledTableCell >{moment.utc(row.order_time).format("YYYY-MM-DD HH:mm:ss")}</StyledTableCell>
                      <StyledTableCell >{row.content}</StyledTableCell>
                      <StyledTableCell >{row.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} <b>֏</b></StyledTableCell>
                      <StyledTableCell align="center" title={row.payment_method} >{row.payment_method === 'card' ?
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M512 80c8.8 0 16 7.2 16 16v32H48V96c0-8.8 7.2-16 16-16H512zm16 144V416c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16V224H528zM64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zm56 304c-13.3 0-24 10.7-24 24s10.7 24 24 24h48c13.3 0 24-10.7 24-24s-10.7-24-24-24H120zm128 0c-13.3 0-24 10.7-24 24s10.7 24 24 24H360c13.3 0 24-10.7 24-24s-10.7-24-24-24H248z" /></svg> :
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 80c0 18-14.3 34.6-38.4 48c-29.1 16.1-72.5 27.5-122.3 30.9c-3.7-1.8-7.4-3.5-11.3-5C300.6 137.4 248.2 128 192 128c-8.3 0-16.4 .2-24.5 .6l-1.1-.6C142.3 114.6 128 98 128 80c0-44.2 86-80 192-80S512 35.8 512 80zM160.7 161.1c10.2-.7 20.7-1.1 31.3-1.1c62.2 0 117.4 12.3 152.5 31.4C369.3 204.9 384 221.7 384 240c0 4-.7 7.9-2.1 11.7c-4.6 13.2-17 25.3-35 35.5c0 0 0 0 0 0c-.1 .1-.3 .1-.4 .2l0 0 0 0c-.3 .2-.6 .3-.9 .5c-35 19.4-90.8 32-153.6 32c-59.6 0-112.9-11.3-148.2-29.1c-1.9-.9-3.7-1.9-5.5-2.9C14.3 274.6 0 258 0 240c0-34.8 53.4-64.5 128-75.4c10.5-1.5 21.4-2.7 32.7-3.5zM416 240c0-21.9-10.6-39.9-24.1-53.4c28.3-4.4 54.2-11.4 76.2-20.5c16.3-6.8 31.5-15.2 43.9-25.5V176c0 19.3-16.5 37.1-43.8 50.9c-14.6 7.4-32.4 13.7-52.4 18.5c.1-1.8 .2-3.5 .2-5.3zm-32 96c0 18-14.3 34.6-38.4 48c-1.8 1-3.6 1.9-5.5 2.9C304.9 404.7 251.6 416 192 416c-62.8 0-118.6-12.6-153.6-32C14.3 370.6 0 354 0 336V300.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 342.6 135.8 352 192 352s108.6-9.4 148.1-25.9c7.8-3.2 15.3-6.9 22.4-10.9c6.1-3.4 11.8-7.2 17.2-11.2c1.5-1.1 2.9-2.3 4.3-3.4V304v5.7V336zm32 0V304 278.1c19-4.2 36.5-9.5 52.1-16c16.3-6.8 31.5-15.2 43.9-25.5V272c0 10.5-5 21-14.9 30.9c-16.3 16.3-45 29.7-81.3 38.4c.1-1.7 .2-3.5 .2-5.3zM192 448c56.2 0 108.6-9.4 148.1-25.9c16.3-6.8 31.5-15.2 43.9-25.5V432c0 44.2-86 80-192 80S0 476.2 0 432V396.6c12.5 10.3 27.6 18.7 43.9 25.5C83.4 438.6 135.8 448 192 448z" /></svg>
                      }</StyledTableCell>
                    </StyledTableRow>
                  ))}
                <div className='sliderDiv'>
                  {pageInfo.previous ?
                    <p onClick={() => getByUrl(pageInfo.previous)}>&#129068;</p>
                    :
                    <p></p>
                  }
                  {pageInfo.current_page}/{pageInfo.total_pages}
                  {pageInfo.next ?
                    <p onClick={() => getByUrl(pageInfo.next)}>&#129070;</p>
                    :
                    <p></p>
                  }

                </div>
              </TableBody>
            }
          </Table>
        </TableContainer>

      </div>
    </>
  )
}

export default Home