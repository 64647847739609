// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menuDiv {
  width: 100%;
  height: 70px;
  background-color: #1976d2;
  position: fixed;
  top: 0;
  display: flex;
  z-index: 5;
}
.menuDiv div {
  width: 50%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 30px;
}
.menuDiv div button {
  color: white;
}
.menuDiv div:last-child {
  justify-content: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/elements/menu.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,eAAA;EACA,MAAA;EACA,aAAA;EACA,UAAA;AACJ;AACI;EACI,UAAA;EACA,aAAA;EACA,mBAAA;EAKA,sBAAA;EACA,aAAA;AAHR;AADQ;EACI,YAAA;AAGZ;AAEI;EACI,yBAAA;AAAR","sourcesContent":[".menuDiv {\n    width: 100%;\n    height: 70px;\n    background-color: #1976d2;\n    position: fixed;\n    top: 0;\n    display: flex;\n    z-index: 5;\n\n    div {\n        width: 50%;\n        display: flex;\n        align-items: center;\n        \n        button {\n            color: white;\n        }\n        box-sizing: border-box;\n        padding: 30px;\n    }\n    div:last-child {\n        justify-content: flex-end;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
