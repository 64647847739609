import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/login/login';
import NotFound from './pages/404/notFound';
import Home from './pages/home/home';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { setAuthorized, setUnauthorized } from './store/features/userSelector';
import './App.css';
import api from './api';
import Statistic from './pages/statistic/statistic';


function App() {
  const isAuthorized = useSelector((state) => state.userSelector.authorized)
  const [load, setLoad] = useState(false)
  const dispatch = useDispatch()


  const authCheckFunc = (token) => {
    api.authCheck(token).then(response => {

    }).catch(error => {
      if (error.message === 'Network Error') {
        setTimeout(window.location.reload(), 10000)
      }
      else if (error.response.status === 403) {
        dispatch(setUnauthorized())
      }
    })
  }


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      dispatch(setAuthorized(token))
    }
    authCheckFunc(token)

    setLoad(true)
  }, [dispatch]);



  return (
    load ?
      <BrowserRouter>
        <Routes>
          {isAuthorized ?
            <>
              <Route path="/" element={<Home />} />
              <Route path="/statistic" element={<Statistic />} />
            </>
            :
            <>
              <Route path="*" element={<Login />} />
            </>
          }
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
      :

      <div className='loaddiv'>
        <img src="https://i.gifer.com/ZKZg.gif" alt="" />
      </div>
  );
}

export default App;
