import React from 'react'
import './menu.scss'
import { Button } from '@mui/material'
import { setUnauthorized } from '../store/features/userSelector'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'


const Menu = () => {
    const dispatch = useDispatch()

    const logout = () => {
        dispatch(setUnauthorized())
        window.location.reload();
    }
    return (
        <div className='menuDiv'>
            <div>
                <Link to="/"><Button variant="text">Data</Button></Link>
                <Link to="/statistic"><Button variant="text">Statistics</Button></Link>
                
            </div>
            <div>
                <Button onClick={logout} variant="text">Logout</Button>
            </div>
        </div>

    )
}

export default Menu