// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loginDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loginSection {
  display: flex;
  gap: 20px;
  flex-direction: column;
  background-color: aliceblue;
  padding: 50px;
  border-radius: 7px;
  box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.19);
}
.loginSection p {
  font-size: 30px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/login/login.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,SAAA;EACA,sBAAA;EACA,2BAAA;EACA,aAAA;EACA,kBAAA;EACA,gDAAA;AACJ;AACI;EACI,eAAA;EACA,kBAAA;AACR","sourcesContent":[".loginDiv{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n}\n\n.loginSection{\n    display: flex;\n    gap: 20px;\n    flex-direction: column;\n    background-color: aliceblue;\n    padding: 50px;\n    border-radius: 7px;\n    box-shadow: 3px 3px 10px 0px rgba(0,0,0,0.19);\n\n    p{\n        font-size: 30px;\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
