// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.statisticDiv {
  padding: 50px 0;
}
.statisticDiv .circleStatistic {
  margin-bottom: 100px;
  position: relative;
}
.statisticDiv .circleStatistic .info {
  position: absolute;
  left: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.statisticDiv .circleStatistic .info p {
  font-size: 20px;
  text-transform: capitalize;
  display: flex;
  gap: 10px;
  margin: 0;
}
.statisticDiv .circleStatistic .info p div {
  height: 20px;
  width: 20px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/pages/statistic/statistic.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AACI;EACI,oBAAA;EACA,kBAAA;AACR;AACQ;EACI,kBAAA;EACA,UAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,SAAA;AACZ;AACY;EACI,eAAA;EACA,0BAAA;EACA,aAAA;EACA,SAAA;EACA,SAAA;AAChB;AACgB;EACI,YAAA;EACA,WAAA;EACA,kBAAA;AACpB","sourcesContent":[".statisticDiv {\n    padding: 50px 0;\n\n    .circleStatistic {\n        margin-bottom: 100px;\n        position: relative;\n\n        .info{\n            position: absolute;\n            left: 50px;\n            height: 100%;\n            display: flex;\n            flex-direction: column;\n            justify-content: center;\n            gap: 10px;\n\n            p{\n                font-size: 20px;\n                text-transform: capitalize;\n                display: flex;\n                gap: 10px;\n                margin: 0;\n\n                div{\n                    height: 20px;\n                    width: 20px;\n                    border-radius: 50%;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
