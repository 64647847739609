import { ApiUrl } from './env'
import axios from "axios";



export default class api {

    static async loginUser(username, password) {
        const data = await axios.post(ApiUrl + '/api/login', {
            username: username,
            password: password
        })
        console.log(data);
        return data
    }
    static async authCheck(token) {
        const data = await axios.post(ApiUrl + '/api/auth',
            {},
            {
                headers: {
                    'AUTHORIZATION': `Token ${token}`,
                }

            })
        return data
    }

    static async getUrl(token, url) {
        const data = await axios.get(`${ApiUrl}/${url}`,
            {
                headers: {
                    'Authorization': `Token ${token}`,
                }

            })
        return data
    }
    static async getData(token, filter) {
        const data = await axios.get(ApiUrl + '/api/orders',
            {
                params: {
                    search: filter.search,
                    corner: filter.corner,
                    order_time_from: filter.order_time_from.split('+')[0],
                    order_time_to: filter.order_time_to.split('+')[0],
                    price_up: filter.price_up,
                    price_to: filter.price_to,
                    payment_method: filter.payment_method,
                    created_time_start: filter.created_time_start,
                    created_time_end: filter.created_time_end,
                },
                headers: {
                    'Authorization': `Token ${token}`,
                }

            })
        return data
    }
    static async getStatData(token, filter) {        
        const data = await axios.get(ApiUrl + '/api/statistic',
            {
                params: {
                    range: filter.range,                    
                    date_from: filter.date_from.split('T')[0],
                    date_to: filter.date_to.split('T')[0],                    
                },
                headers: {
                    'Authorization': `Token ${token}`,
                }

            })
        return data
    }
    static async export(token, filter) {
        const data = await axios.post(ApiUrl + '/api/export',
            {
                search: filter.search,
                corner: filter.corner,
                order_time_from: filter.order_time_from,
                order_time_to: filter.order_time_to,
                price_up: filter.price_up,
                price_to: filter.price_to,
                payment_method: filter.payment_method,
                created_time_start: filter.created_time_start,
                created_time_end: filter.created_time_end,
            }, {
            headers: {
                'Authorization': `Token ${token}`,
            }

        })
        return data
    }
}