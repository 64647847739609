import { createSlice } from '@reduxjs/toolkit'

export const userSelectorSlice = createSlice({
    name: 'userSelector',
    initialState: {
        authorized: false,        
        token: ''
    },
    reducers: {           
        setAuthorized: (state, action) => {
            state.authorized = true
            state.token = action.payload            
            localStorage.setItem('token', action.payload)
        },        
        setUnauthorized: (state) => {            
            state.token = null
            localStorage.removeItem('token')
        },
    },
})

export const {
    setAuthorized,
    setUnauthorized,    
} = userSelectorSlice.actions

export default userSelectorSlice.reducer