import Menu from '../../elements/menu'
import React, { useEffect, useState } from 'react'
import './statistic.scss'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux'
import moment from 'moment';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import api from '../../api'



const COLORS = {
    "pinsa": '#cfc800',
    "ikigai": '#00a19e',
    "crepe brulee": '#125eb5',
    "corner for friends": '#8884d8',
    "darte": '#AD26AF',
    "bagel": '#AF264B',
    "beer": '#ff0000',
    "natasha": '#038028',
    "urban": '#000000'
}


const Statistic = () => {
    const token = useSelector((state) => state.userSelector.token)
    const [order_date_from, setOrder_date_from] = useState(null)
    const [order_date_to, setOrder_date_to] = useState(null)
    const [range, setRange] = useState('day')
    const [linearStat, setLinearStat] = useState([])
    const [circleStat, setCircleStat] = useState([])

    const getStatFunc = () => {

        let order_datetime_from_formated = moment(order_date_from, "YYYY-MM-DDTHH:mm:ss").format()
        let order_datetime_to_formated = moment(order_date_to, "YYYY-MM-DDTHH:mm:ss").format()
        if (order_datetime_from_formated === 'Invalid date') {
            order_datetime_from_formated = null
        }
        if (order_datetime_to_formated === 'Invalid date') {
            order_datetime_to_formated = null
        }
        let filter = {
            range: range,
            date_from: order_datetime_from_formated,
            date_to: order_datetime_to_formated
        }
        api.getStatData(token, filter).then(
            response => {
                setLinearStat(response.data.linear)
                setCircleStat(response.data.circle)

            }
        ).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        console.log(order_date_from)
        console.log(order_date_to)
        var currentDate = new Date();
        var firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        var lastDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        setOrder_date_from(firstDayOfMonth)
        setOrder_date_to(lastDayOfMonth)
    }, [token])

    useEffect(() => {
        getStatFunc()
    }, [order_date_from, order_date_to, range])

    return (
        <>
            <Menu />
            <div className='mainFilter'>
                <div className='filterDiv'>
                    <div className='rangeFilterDiv'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    value={dayjs(order_date_from)}
                                    id='setOrder_date_from'
                                    onChange={e => setOrder_date_from(e.$d)}
                                    label="Date from"
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    value={dayjs(order_date_to)}
                                    id='setOrder_date_to'
                                    onChange={e => setOrder_date_to(e.$d)}
                                    label="Date to"
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </div>
                    <div className='rangeFilterDiv'>
                        <RadioGroup
                            row
                            onChange={(e) => setRange(e.target.value)}
                        >
                            <FormControlLabel value="day" control={<Radio />} label="Day" />
                            <FormControlLabel value="hour" control={<Radio />} label="Hour" />
                            <FormControlLabel value="halfHour" control={<Radio />} label="30 min" />
                            <FormControlLabel value="fiveMin" control={<Radio />} label="5 min" />
                        </RadioGroup>
                    </div>
                </div>
            </div>
            <div className='statisticDiv'>
                <div className='circleStatistic' style={{ width: '100%', height: 300 }}>
                    <div className='info'>
                        {circleStat.map(item =>
                                <p> <div style={{backgroundColor: COLORS[item.name]}}></div> {item.name}  - {item.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") } ֏</p>                                
                            )}
                    </div>

                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart width={400} height={400}>
                            <Pie data={circleStat} dataKey="value" cx="50%" cy="50%" outerRadius={110} fill="#82ca9d" label >
                                {circleStat.map((entry, index) => (
                                    <Cell onMouseEnter={() => console.log(entry.name, COLORS[entry.name])} key={`cell-${index}`} fill={COLORS[entry.name]} />
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>

                </div>
                <div style={{ width: '100%', height: 500 }}>

                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart
                            width={500}
                            height={300}
                            data={linearStat}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            {Object.keys(COLORS).map((item, i) =>
                                <Line type="monotone" dataKey={item} stroke={COLORS[item]} activeDot={{ r: 8 }} />

                            )}
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div >
        </>
    )
}

export default Statistic
